<template>
  <svg width="48" height="72" viewBox="0 0 48 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="72" rx="1.74562" fill="#F3F4F6" />
    <rect x="13.5" y="48" width="21" height="3" rx="1.5" fill="#D0D5DD" />
    <rect x="4.5" y="54" width="39" height="3" rx="1.5" fill="#D0D5DD" />
    <path
      d="M37.8219 37.5471C37.8321 37.5276 37.8504 37.5109 37.8743 37.4991C37.8982 37.4873 37.9266 37.481 37.9557 37.481C37.9848 37.481 38.0132 37.4873 38.0371 37.4991C38.061 37.5109 38.0793 37.5276 38.0896 37.5471L38.8133 39.0109H40.8574C40.8867 39.0109 40.9152 39.0173 40.9392 39.0292C40.9632 39.0411 40.9814 39.058 40.9916 39.0776C41.0017 39.0971 41.0032 39.1184 40.9958 39.1386C40.9884 39.1588 40.9726 39.1769 40.9504 39.1904L39.2423 40.2006L39.9572 41.7326C39.9668 41.7531 39.9669 41.7753 39.9577 41.7959C39.9484 41.8165 39.9302 41.8345 39.9057 41.8472C39.8812 41.8599 39.8517 41.8668 39.8213 41.8668C39.791 41.8667 39.7615 41.8598 39.737 41.847L37.9554 40.9146L36.173 41.847C36.1485 41.8596 36.1191 41.8663 36.089 41.8662C36.0588 41.8661 36.0295 41.8592 36.0052 41.8465C35.9809 41.8338 35.9628 41.816 35.9535 41.7955C35.9443 41.7751 35.9443 41.753 35.9537 41.7326L36.6686 40.2006L34.9602 39.1904C34.938 39.1769 34.9221 39.1588 34.9148 39.1386C34.9074 39.1184 34.9089 39.0971 34.919 39.0776C34.9291 39.058 34.9474 39.0411 34.9714 39.0292C34.9954 39.0173 35.0239 39.0109 35.0532 39.0109H37.0975L37.8219 37.5471Z"
      fill="#98A2B3"
    />
    <path
      d="M30.5904 37.5471C30.6007 37.5276 30.619 37.5109 30.6429 37.4991C30.6668 37.4873 30.6952 37.481 30.7243 37.481C30.7534 37.481 30.7818 37.4873 30.8057 37.4991C30.8296 37.5109 30.8479 37.5276 30.8581 37.5471L31.5819 39.0109H33.626C33.6552 39.0109 33.6837 39.0173 33.7077 39.0292C33.7317 39.0411 33.75 39.058 33.7601 39.0776C33.7703 39.0971 33.7717 39.1184 33.7644 39.1386C33.757 39.1588 33.7411 39.1769 33.7189 39.1904L32.0108 40.2006L32.7258 41.7326C32.7353 41.7531 32.7355 41.7753 32.7262 41.7959C32.717 41.8165 32.6988 41.8345 32.6743 41.8472C32.6498 41.8599 32.6202 41.8668 32.5899 41.8668C32.5596 41.8667 32.53 41.8598 32.5056 41.847L30.724 40.9146L28.9416 41.847C28.9171 41.8596 28.8877 41.8663 28.8575 41.8662C28.8274 41.8661 28.7981 41.8592 28.7738 41.8465C28.7494 41.8338 28.7314 41.816 28.7221 41.7955C28.7128 41.7751 28.7129 41.753 28.7222 41.7326L29.4371 40.2006L27.7288 39.1904C27.7066 39.1769 27.6907 39.1588 27.6833 39.1386C27.676 39.1184 27.6774 39.0971 27.6876 39.0776C27.6977 39.058 27.716 39.0411 27.74 39.0292C27.7639 39.0173 27.7925 39.0109 27.8217 39.0109H29.8661L30.5904 37.5471Z"
      fill="#98A2B3"
    />
    <path
      d="M23.358 37.5471C23.3683 37.5276 23.3866 37.5109 23.4105 37.4991C23.4344 37.4873 23.4628 37.481 23.4919 37.481C23.5209 37.481 23.5493 37.4873 23.5732 37.4991C23.5972 37.5109 23.6154 37.5276 23.6257 37.5471L24.3495 39.0109H26.3936C26.4228 39.0109 26.4513 39.0173 26.4753 39.0292C26.4993 39.0411 26.5176 39.058 26.5277 39.0776C26.5378 39.0971 26.5393 39.1184 26.5319 39.1386C26.5246 39.1588 26.5087 39.1769 26.4865 39.1904L24.7784 40.2006L25.4933 41.7326C25.5029 41.7531 25.5031 41.7753 25.4938 41.7959C25.4846 41.8165 25.4664 41.8345 25.4418 41.8472C25.4173 41.8599 25.3878 41.8668 25.3575 41.8668C25.3271 41.8667 25.2976 41.8598 25.2731 41.847L23.4916 40.9146L21.7091 41.847C21.6847 41.8596 21.6552 41.8663 21.6251 41.8662C21.595 41.8661 21.5657 41.8592 21.5413 41.8465C21.517 41.8338 21.4989 41.816 21.4897 41.7955C21.4804 41.7751 21.4805 41.753 21.4898 41.7326L22.2047 40.2006L20.4963 39.1904C20.4741 39.1769 20.4583 39.1588 20.4509 39.1386C20.4435 39.1184 20.445 39.0971 20.4551 39.0776C20.4653 39.058 20.4836 39.0411 20.5075 39.0292C20.5315 39.0173 20.5601 39.0109 20.5893 39.0109H22.6337L23.358 37.5471Z"
      fill="#98A2B3"
    />
    <path
      d="M16.761 37.5471C16.7692 37.5276 16.7839 37.5109 16.803 37.4991C16.8221 37.4873 16.8448 37.481 16.8681 37.481C16.8914 37.481 16.9141 37.4873 16.9332 37.4991C16.9523 37.5109 16.967 37.5276 16.9752 37.5471L17.5542 39.0109H19.1895C19.2128 39.0109 19.2357 39.0173 19.2549 39.0292C19.274 39.0411 19.2887 39.058 19.2968 39.0776C19.3049 39.0971 19.3061 39.1184 19.3002 39.1386C19.2943 39.1588 19.2816 39.1769 19.2638 39.1904L17.8973 40.2006L18.4693 41.7326C18.4769 41.7531 18.4771 41.7753 18.4697 41.7959C18.4623 41.8165 18.4477 41.8345 18.4281 41.8472C18.4085 41.8599 18.3848 41.8668 18.3606 41.8668C18.3363 41.8667 18.3127 41.8598 18.2931 41.847L16.8679 40.9146L15.4419 41.847C15.4223 41.8596 15.3988 41.8663 15.3747 41.8662C15.3506 41.8661 15.3271 41.8592 15.3077 41.8465C15.2882 41.8338 15.2738 41.816 15.2664 41.7955C15.2589 41.7751 15.259 41.753 15.2664 41.7326L15.8384 40.2006L14.4717 39.1904C14.4539 39.1769 14.4412 39.1588 14.4353 39.1386C14.4294 39.1184 14.4306 39.0971 14.4387 39.0776C14.4468 39.058 14.4615 39.0411 14.4806 39.0292C14.4998 39.0173 14.5227 39.0109 14.546 39.0109H16.1815L16.761 37.5471Z"
      fill="#98A2B3"
    />
    <path
      d="M10.1119 37.5471C10.1222 37.5276 10.1405 37.5109 10.1644 37.4991C10.1883 37.4873 10.2167 37.481 10.2458 37.481C10.2748 37.481 10.3032 37.4873 10.3271 37.4991C10.3511 37.5109 10.3694 37.5276 10.3796 37.5471L11.1034 39.0109H13.1475C13.1767 39.0109 13.2052 39.0173 13.2292 39.0292C13.2532 39.0411 13.2715 39.058 13.2816 39.0776C13.2917 39.0971 13.2932 39.1184 13.2858 39.1386C13.2785 39.1588 13.2626 39.1769 13.2404 39.1904L11.5323 40.2006L12.2472 41.7326C12.2568 41.7531 12.257 41.7753 12.2477 41.7959C12.2385 41.8165 12.2203 41.8345 12.1958 41.8472C12.1712 41.8599 12.1417 41.8668 12.1114 41.8668C12.081 41.8667 12.0515 41.8598 12.027 41.847L10.2455 40.9146L8.46304 41.847C8.43856 41.8596 8.40915 41.8663 8.37902 41.8662C8.34889 41.8661 8.31957 41.8592 8.29525 41.8465C8.27092 41.8338 8.25285 41.816 8.24359 41.7955C8.23433 41.7751 8.23437 41.753 8.2437 41.7326L8.95862 40.2006L7.25025 39.1904C7.22804 39.1769 7.21218 39.1588 7.20481 39.1386C7.19744 39.1184 7.19892 39.0971 7.20905 39.0776C7.21918 39.058 7.23746 39.0411 7.26145 39.0292C7.28543 39.0173 7.31396 39.0109 7.34319 39.0109H9.38757L10.1119 37.5471Z"
      fill="#98A2B3"
    />
    <rect x="10.7998" y="7.2002" width="25.2" height="25.2" fill="#98A2B3" />
  </svg>
</template>
