<template>
  <div class="flex">
    <div class="flex flex-col items-center">
      <ReviewInteractionStatusIcon :type="ReviewInteractionType.REMINDER_CHECK" />
      <div class="mt-4 h-full border-l border-solid border-grey-300" />
    </div>
    <div class="ml-2 mt-0.5">
      <div class="text-body-s mb-3 text-grey-500">
        {{ t('Reviews.product.review.history.datetime', { dateTime: formattedDateTime }) }}:
        <span class="text-body-s-bold">{{ t('Reviews.product.review.history.reminderSent') }}</span>
      </div>
      <div v-if="!reminder.automatic" class="text-body-s mb-4 text-grey-700">
        {{ t('Reviews.product.review.history.sender', { senderName }) }}
      </div>
      <div v-if="hint" class="text-body-m mb-4">{{ hint }}</div>
      <div v-if="reminder.message" class="bg-grey-200 p-4" v-html="nl2br(reminder.message)" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { nl2br } from '@ramp106/omrjs-core-ui'
import { differenceInDays } from 'date-fns'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useUserQuery } from '@/gql/myomr'

import ReviewInteractionStatusIcon from './ReviewInteractionStatusIcon.vue'
import { ReviewInteractionType, type SurveyResponseReminder } from './types'

const REMINDER_RESEND_DAYS = 14

const props = defineProps<{
  authorName?: string
  reminder: SurveyResponseReminder
}>()

const { t, d } = useI18n()

const { result } = useUserQuery({ userId: String(props.reminder.userId) })

const senderName = computed(() => {
  const user = result.value?.user

  if (user) {
    return [user.firstName, user.lastName].filter((x) => !!x).join(' ')
  }
  return t('Reviews.product.review.history.unknownSender')
})

const formattedDateTime = computed(() => d(props.reminder.sentAt || props.reminder.createdAt, 'dateTimeShort'))

const daysLeft = computed(() => {
  if (!props.reminder.sentAt) {
    return 0
  }
  return differenceInDays(new Date(props.reminder.sentAt), new Date()) + REMINDER_RESEND_DAYS
})

const hint = computed(() => {
  const authorName = props.authorName || t('Reviews.product.review.history.unknownAuthor')

  if (props.reminder.automatic) {
    return t('Reviews.product.review.history.automaticReminderHint', { authorName })
  } else if (daysLeft.value > 0) {
    return t('Reviews.product.review.history.reminderHint', {
      authorName,
      daysLeft: daysLeft.value,
    })
  }

  return ''
})
</script>
