<template>
  <div class="mt-5 rounded-lg border border-grey-300 bg-white p-6 dark:border-grey-700 dark:bg-grey-950">
    <h2 class="text-headline-m">{{ t('PartnerCompany.contactForAppointments') }}</h2>
    <p class="mt-2">{{ t('PartnerCompany.contactForAppointmentsHelpText') }}</p>
    <hr class="dark:bg-gray-700 my-4 h-px border-0 bg-grey-300" />

    <TextInput v-model="model.firstname" class="mb-5" :label="t('ContactEditForm.firstName')" />
    <TextInput v-model="model.name" class="mb-5" :label="t('ContactEditForm.name')" />
    <TextInput v-model="model.title" class="mb-5" :label="t('ContactEditForm.position')" />
    <TextInput v-model="model.email" class="mb-5" :label="t('ContactEditForm.email')" :error="getValidationErrors(v$.email)" />
    <TextInput v-model="model.phone" class="mb-5" :label="t('ContactEditForm.phone')" :error="getValidationErrors(v$.phone)" />
  </div>
</template>
<script setup lang="ts">
import { TextInput } from '@ramp106/omrjs-core-ui'
import type { Validation } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'

import type { UpdateContactMutationVariables } from '@/gql/myomr'
import { getValidationErrors } from '@/helpers/validationHelper'

const { t } = useI18n()

defineProps<{
  v$: Validation
}>()

const model = defineModel<NonNullable<UpdateContactMutationVariables>>({
  default: () => ({
    firstname: '',
    name: '',
    title: '',
    email: '',
    phone: '',
  }),
})
</script>
