<template>
  <TabBar gap="gap-0">
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsAgencyRouteTabs.Details"
      :to="{ name: ReviewsRouteName.ReviewsAgencyViewDetails }"
    >
      {{ t('Reviews.agency.view.tab.details') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsAgencyRouteTabs.Partners"
      :to="{ name: ReviewsRouteName.ReviewsAgencyViewPartners }"
    >
      {{ t('Reviews.agency.view.tab.partners') }}
    </NavTabBarItem>
  </TabBar>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { ReviewsRouteName, ReviewsAgencyRouteTabs } from '@/router/types'
import { TabBar, NavTabBarItem } from '@/ui/Tabs'

const { t } = useI18n()
const route = useRoute()

const disabled = computed(() => {
  // TODO: enable when edit page is ready
  //return route.name?.toString().startsWith(ReviewsRouteName.ReviewsAgencyEdit)
  return false
})
</script>
