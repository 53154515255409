import type { ProductBadgesTypes } from '@ramp106/omrjs-reviews-ui'

import type { AgencyById } from '@/gql/reviews'

type Badge = AgencyById['products'][number]['productBadges'][number]

export const mapBadgeToProductBadge = ({ category, badgeName, timeframe }: Badge): ProductBadgesTypes.Badge => ({
  name: category?.displayOrTitle || badgeName,
  fullName: category?.displayOrTitle ? `${badgeName} - ${category.displayOrTitle}` : badgeName,
  timePeriodLabel: timeframe || '',
})
