<template>
  <div v-if="products.length > 0" class="py-6">
    <div class="pb-12">
      <div class="text-headline-xs mb-1 flex items-center justify-between">
        <span>{{ t('Reviews.agency.view.partners.title') }}</span>
        <OpenAgencyProfile :slug="agency.slug" />
      </div>
      <div class="text-body-s">{{ t('Reviews.agency.view.partners.description') }}</div>
    </div>

    <CarouselContainer :item-min-width="230" :items-to-show="4" :items-to-slide="4" :spacing="24">
      <CarouselSlide v-for="(product, i) in products" :key="product.name" :index="i">
        <ProductCard data-testid="product-card" :product="product" @click="openReviewsProduct(product.slug)" />
      </CarouselSlide>
    </CarouselContainer>
  </div>
  <ReviewsEmptyView
    v-else
    class="py-8"
    :title="t('Reviews.agency.view.partners.noPartnersTitle')"
    :description="t('Reviews.agency.view.partners.noPartnersInfo')"
    :action="{
      label: t('Reviews.agency.view.actions.addPartners'),
      disabled: true,
      to: {
        name: ReviewsRouteName.ReviewsAgencyView,
        params: { agencyId: agency.id },
      },
    }"
  >
    <template #image>
      <NoPartnersIcon />
    </template>
  </ReviewsEmptyView>
</template>

<script setup lang="ts">
import { CarouselContainer, CarouselSlide } from '@ramp106/omrjs-core-ui'
import { ProductCard } from '@ramp106/omrjs-reviews-ui'
import { reviewsPaths } from '@ramp106/omrjs-routing'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import NoPartnersIcon from '@/components/ReviewsAgenciesManagements/Partners/NoPartnersIcon.vue'
import ReviewsEmptyView from '@/components/ReviewsEmptyView.vue'
import { baseOmrUrl } from '@/config/env'
import { mapProductToProductCard, type AgencyById } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types.ts'

import OpenAgencyProfile from '../OpenAgencyProfile.vue'

const props = defineProps<{
  agency: AgencyById
}>()

const { t, locale } = useI18n()

const products = computed(() => props.agency.products.map(mapProductToProductCard))

function openReviewsProduct(slug: string) {
  const reviewsProductUrl = `${baseOmrUrl}${reviewsPaths.product({ locale: locale.value, slug })}`
  window.open(reviewsProductUrl, '_blank')
}
</script>
