<template>
  <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsAgencies }">
    {{ t('Reviews.agency.view.partnersTitle', { agencyName: agency.title }) }}
    <template #actions> </template>
  </ContentCardSubheader>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { AgencyById } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'

defineProps<{
  agency: Pick<AgencyById, 'id' | 'title'>
}>()

const { t } = useI18n()
</script>
