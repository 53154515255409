import type { ProductCardTypes } from '@ramp106/omrjs-reviews-ui'

import type { ProductBasicDataFragment } from '@/gql/reviews'

import { mapBadgeToProductBadge } from './mapBadgeToProductBadge'

export const mapProductToProductCard = (product: ProductBasicDataFragment): ProductCardTypes.Product & { slug: string } => ({
  slug: product.slug,
  name: product.title,
  isSuite: product.isSuite,
  link: undefined,
  logoUrl: product.logo?.url || undefined,
  reviewCount: product.reviewCount,
  rating: product.averageRecommendationRating ?? undefined,
  badges: product.productBadges.map(mapBadgeToProductBadge),
})
