<template>
  <a class="btn-text-purple-l" :href="reviewsAgencyUrl" target="_blank">
    {{ t('Reviews.agency.view.actions.openAgency') }}
    <MdiSvg :path="mdiArrowTopRight" />
  </a>
</template>

<script setup lang="ts">
import { mdiArrowTopRight } from '@mdi/js'
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { reviewsPaths } from '@ramp106/omrjs-routing'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { baseOmrUrl } from '@/config/env'

const props = defineProps<{
  slug: string
}>()

const { t, locale } = useI18n()

const reviewsAgencyUrl = computed(() => {
  return `${baseOmrUrl}${reviewsPaths.agency({ locale: locale.value, slug: props.slug })}`
})
</script>
